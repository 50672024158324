import Layout from "../components/layout"
import React from "react"
import Seo from "../components/seo"
import OGMobile from "../images/og-mobile.png"

import Particles from "../components/particles"
import { motion } from "framer-motion"

import appStoreBadge from "../images/mobile-landing/app-store-badge.svg"
import playStoreBadge from "../images/mobile-landing/play-store-badge.svg"
import phoneMobile from "../images/mobile-landing/mobile-iphone.svg"
import mobileRow1 from "../images/mobile-landing/mobile-row-1.svg"
import mobileRow2 from "../images/mobile-landing/mobile-row-2.svg"
import bubble1 from "../images/person-bubble-1.svg"

const MobileLanding = () => (
  <div className="grad-layout">
    <Layout>
      <Seo
        title="Get the mobile app"
        description="Download the free mobile app for iOS or Android for quick access to your team's incidents and schedules. Respond with ease."
        ogImage={OGMobile}
      />
      <section>
        <div className="container top-container">
          <div className="hero--section">
            <div
              data-sal="slide-right"
              data-sal-easing="ease"
              data-sal-delay="400"
              data-sal-duration="1000"
              className="content"
            >
              <h1
                className="main-title"
                style={{
                  fontSize: "4em",
                  lineHeight: "1.2",
                  marginBottom: "0.3em",
                }}
              >
                Get the free mobile app
              </h1>
              <h4 style={{ marginBottom: "2em" }}>
                Get the quickest access to your team's incidents and schedules.
                Enable push notifications to receive instant, detailed alerts
                and respond with ease.
              </h4>
              <div className="d-f mt-1">
                <motion.div
                  whileHover={{ scale: 0.98 }}
                  whileTap={{ scale: 1 }}
                  className="mt-2 btn--container"
                >
                  <a href="https://apps.apple.com/us/app/id1450092852">
                    <img alt="app store badge" src={appStoreBadge} />
                  </a>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 0.98 }}
                  whileTap={{ scale: 1 }}
                  className="mt-2 btn--container"
                >
                  <a href="https://play.google.com/store/apps/details?id=io.readyfive.readyfive">
                    <img
                      alt="play store badge"
                      src={playStoreBadge}
                      style={{ height: "58px", marginLeft: "1em" }}
                    />
                  </a>
                </motion.div>
              </div>
            </div>

            <div className="image-container--hero mobile-landing-hero">
              <img
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="mobile-bubble"
                src={bubble1}
                alt="Bubble 2"
              />
              <img
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                data-sal-delay="500"
                className="mobile-row-1"
                src={mobileRow1}
                alt="Row 1"
              />
              <img
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                data-sal-delay="700"
                className="mobile-row-2"
                src={mobileRow2}
                alt="Row 2"
              />
              <img
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="mobile-landing-phone"
                src={phoneMobile}
                alt="Ready Five App"
              />
            </div>
          </div>
        </div>
        <Particles />
      </section>
    </Layout>
  </div>
)

export default MobileLanding
